<template>
  <v-dialog
    v-model="dialog"
    width="450px"
    scrollable
    @input="onDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        v-bind="attrs"
        link
        @click="$_.delay(on.click, 0, $event)">
        <v-list-item-subtitle>
          <span> {{ $t('setting') }}</span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-card class="snt-template-form__card">
      <v-card-title class="pt-6 pb-0"></v-card-title>
      <v-card-text class="px-6">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <div class="snt-template-form__label mb-4">
            {{ $t('templateName') }}
          </div>
          <v-text-field
            v-model="name"
            :rules="rule.name"
            :placeholder="$t('templateName')"
            class="snt-template-form__field"
            autocomplete="off"
            background-color="#ffffff"
            autofocus
            outlined
            dense />
          <div class="snt-template-form__label mb-2">
            {{ $t('templateCategory') }}
          </div>
          <v-chip-group
            v-model="categories"
            :rules="rule.categories"
            active-class="primary"
            multiple
            column>
            <v-chip
              v-for="category in salePageTemplateCategoryEnum"
              :key="`chip-category-${category.name}`"
              :value="category.name"
              color="#EBEBEB">
              {{ $t(category.description) }}
            </v-chip>
          </v-chip-group>
          <v-input
            v-model="categories"
            :rules="rule.categories"
            class="px-3 mb-2"
          />
          <div class="snt-template-form__label mb-4">
            {{ $t('price') }}
          </div>
          <v-text-field
            v-model="price"
            :rules="rule.price"
            :placeholder="$t('price')"
            class="snt-template-form__field"
            type="number"
            background-color="#ffffff"
            suffix="THB"
            outlined
            dense />
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-6 px-6">
        <v-spacer />
        <v-btn
          class="snt-template-form__btn px-4"
          color="primary"
          outlined
          @click="setDialog(false)">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          :loading="submitting"
          class="snt-template-form__btn px-4"
          color="primary"
          depressed
          @click="submit()">
          {{ $t('completed') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { GET_SALEPAGETEMPLATECATEGORY_ENUM, UPDATE_SALEPAGETEMPLATE } from '@/resources/graphql'

export default {
  apollo: {
    salePageTemplateCategoryEnum: () => ({
      query: GET_SALEPAGETEMPLATECATEGORY_ENUM,
      update: (data) => data.__type.enumValues
    })
  },
  props: {
    itemId: {
      type: String,
      required: true
    },
    itemName: {
      type: String,
      default: ''
    },
    itemCategories: {
      type: [Array],
      required: true
    },
    itemPrice: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      valid: false,
      submitting: false,
      name: '',
      categories: [],
      price: '0',
      rule: {
        name: [
          (v) => !!v || this.$t('required'),
          (v) => (!!v && v.length > 2) || this.$t('minLength', [2]),
          (v) => (!!v && v.length <= 24) || this.$t('maxLength', [24])
        ],
        categories: [
          (v) => !!v || this.$t('required'),
          (v) => (!!v && !!v.length) || this.$t('required')
        ],
        price: [
          (v) => v !== '' || this.$t('required')
        ]
      }
    }
  },
  methods: {
    async submit () {
      if (this.$refs.form.validate()) {
        this.submitting = true
        await this.$apollo.mutate({
          mutation: UPDATE_SALEPAGETEMPLATE,
          variables: {
            input: {
              id: this.itemId,
              name: this.name,
              price: this.price.toString(),
              categories: this.categories
            }
          }
        })
        this.submitting = false
        this.setDialog(false)
      }
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    },
    onDialog (dialog) {
      if (dialog) {
        this.name = this.itemName
        this.categories = this.itemCategories
        this.price = this.itemPrice
      }
    }
  }
}
</script>

<style scoped>
  .snt-template-action .v-list-item__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }
  .snt-template-form__card {
    border-radius: 8px;
  }
  .snt-template-form__label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #6B6B6B;
  }
  .snt-template-form__field {
    border-radius: 8px;
  }
  .snt-template-form__btn {
    border-radius: 8px;
  }
</style>
