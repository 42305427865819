<template>
  <v-dialog
    v-model="dialog"
    width="320px"
    scrollable>
    <template v-slot:activator="{ attrs }">
      <v-list-item
        v-bind="attrs"
        :disabled="disabled"
        link
        @click="setDialog(true, true)">
        <v-list-item-subtitle>
          <span> {{ $t('unpublish.btn') }}</span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-card class="snt-template-unpublish__card">
      <v-card-title class="pt-6 px-6 pb-0"></v-card-title>
      <v-card-text class="px-6">
        <div class="snt-template-unpublish__action">
          <v-btn
            x-small
            icon
            @click="setDialog(false)">
            <v-icon
              color="#6C6C6C"
              size="16">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="text-center mb-4">
          <v-icon
            color="error"
            size="64">
            mdi-lan-disconnect
          </v-icon>
        </div>
        <div class="snt-template-unpublish__title">
          {{ $t('unpublish.title') }}
        </div>
        <div class="snt-template-unpublish__description">
          {{ $t('unpublish.description') }}
        </div>
      </v-card-text>
      <v-card-actions class="pb-6 px-6">
        <v-spacer />
        <v-btn
          class="snt-template-unpublish__btn px-4"
          text
          @click="setDialog(false)">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :loading="submitting"
          class="snt-template-unpublish__btn px-4 ml-3"
          color="error"
          depressed
          @click="submit()">
          {{ $t('unpublish.btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UPDATE_STATUS_SALEPAGETEMPLATE } from '@/resources/graphql'

export default {
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    disabled: {
      type: [Boolean],
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false
    }
  },
  methods: {
    async submit () {
      this.submitting = true
      await this.$apollo.mutate({
        mutation: UPDATE_STATUS_SALEPAGETEMPLATE,
        variables: {
          input: {
            id: this.itemId,
            status: 'UNPUBLISHED'
          }
        }
      })
      this.submitting = false
      this.setDialog(false)
    },
    setDialog (dialog = false, timeout = false) {
      if (timeout) {
        setTimeout(() => {
          this.dialog = dialog
        })
      } else {
        this.dialog = dialog
      }
    }
  }
}
</script>

<style scoped>
  .snt-template-action .v-list-item__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }
  .snt-template-unpublish__card {
    border-radius: 8px;
  }
  .snt-template-unpublish__action {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .snt-template-unpublish__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #6B6B6B;
  }
  .snt-template-unpublish__description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #B1B1B1;
  }
  .snt-template-unpublish__btn {
    border-radius: 8px;
  }
</style>
