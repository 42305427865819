<template>
  <div class="snt-templates">
    <navbar />
    <div class="py-4 px-8">
      <div class="snt-templates__headline">
        {{ $t('allTemplate') }}
      </div>
      <template-home v-if="countModifier === 1 && !salePageTemplates.nodes.length" />
      <div v-else-if="countModifier">
        <div class="d-flex column-gap-3 mb-4 flex-wrap">
          <div class="snt-templates-search mb-1">
            <v-text-field
              :value="search"
              :placeholder="$t('search')"
              class="snt-templates__field"
              prepend-inner-icon="mdi-magnify"
              autocomplete="off"
              background-color="#ffffff"
              outlined
              hide-details
              dense
              @input="debounceSetSearch($event)" />
          </div>
          <div class="snt-templates-category mb-1">
            <v-select
              :value="category"
              :items="salePageTemplateCategoryEnum"
              :placeholder="$t('category')"
              :item-text="(v) => $t(v.description)"
              :menu-props="{
                'content-class': 'snt-template-category__menu',
                'origin': 'center center',
                'transition':'slide-y-transition',
                'nudge-top': '0',
                'offset-y': true
              }"
              item-value="name"
              class="snt-templates__field"
              background-color="#ffffff"
              outlined
              hide-details
              clearable
              dense
              @input="setCategory($event)" />
          </div>
          <v-spacer />
          <create-button />
        </div>
        <div class="snt-templates__list">
          <div class="d-flex flex-wrap column-gap-4 row-gap-4">
            <template v-for="item in salePageTemplates.nodes">
              <v-card
                :key="`tmp-${item.id}`"
                class="snt-template-card"
                width="300"
                outlined>
                <v-img
                  :src="item.snapshotUrl"
                  class="snt-template-img"
                  height="150px">
                  <div class="d-flex">
                    <template-upload-cover :item-id="item.id" />
                    <v-spacer />
                    <template-action v-bind="$_.prefix('item', item)" />
                  </div>
                </v-img>
                <v-card-text>
                  <div class="d-flex">
                    <div class="w-100 mr-1">
                      <div class="snt-template-card__text snt-template-card-name text-overflow mb-1">
                        {{ item.name }}
                      </div>
                      <div class="d-flex">
                        <div class="snt-template-card__text">
                          v.{{ item.version }}
                        </div>
                      </div>
                    </div>
                    <v-spacer />
                    <div>
                      <v-btn
                        :to="`/templates/${item.id}`"
                        color="primary"
                        rounded
                        depressed>
                        {{ $t('edit') }}
                      </v-btn>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </div>
          <v-container v-if="!salePageTemplates.nodes.length">
            <div
              v-if="!salePageTemplates.nodes.length"
              class="no-data">
              <v-icon size="38">
                mdi-emoticon-cry-outline
              </v-icon>
              {{ $t('noitem') }}
            </div>
          </v-container>
        </div>
        <infinite-loading
          :identifier="infiniteId"
          @infinite="infiniteHandler($event)">
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_SALEPAGETEMPLATES, GET_SALEPAGETEMPLATECATEGORY_ENUM } from '@/resources/graphql'
import Navbar from '@/components/Navbar.vue'
import TemplateAction from './components/TemplateAction.vue'
import CreateButton from './components/CreateButton.vue'
import TemplateHome from './components/TemplateHome.vue'
import TemplateUploadCover from './components/TemplateUploadCover.vue'

export default {
  components: {
    Navbar,
    TemplateAction,
    CreateButton,
    TemplateHome,
    TemplateUploadCover
  },
  apollo: {
    salePageTemplateCategoryEnum: () => ({
      query: GET_SALEPAGETEMPLATECATEGORY_ENUM,
      update: (data) => data.__type.enumValues
    }),
    salePageTemplates: () => ({
      query: GET_SALEPAGETEMPLATES,
      fetchPolicy: 'network-only',
      debounce: 300,
      variables () {
        return {
          page: 1,
          perPage: 10,
          filter: {
            category: this.category ? [this.category] : [],
            search: this.search
          }
        }
      },
      result () {
        this.countModifier += 1
        this.infiniteId += 1
      }
    })
  },
  data () {
    return {
      infiniteId: +new Date(),
      countModifier: 0,
      submitting: false,
      page: 1,
      salePageTemplateCategoryEnum: [],
      salePageTemplates: {
        nodes: [],
        hasNextPage: false
      },
      search: '',
      category: null
    }
  },
  created () {
    this.debounceSetSearch = this.$_.debounce(this.setSearch, 300)
  },
  methods: {
    joinCategories (categories) {
      return categories.map((name) => {
        const item = this.$_.find(this.salePageTemplateCategoryEnum, (v) => v.name === name)
        return this.$t(item.description)
      }).join(', ')
    },
    setSearch (search = '') {
      this.page = 1
      this.search = search
      this.infiniteId += 1
    },
    setCategory (category) {
      this.page = 1
      this.category = category
      this.infiniteId += 1
    },
    async infiniteHandler ($state) {
      if (this.salePageTemplates.hasNextPage) {
        this.page += 1
        await this.$apollo.queries.salePageTemplates.fetchMore({
          variables: {
            page: this.page,
            perPage: 10,
            filter: {
              category: this.category ? [this.category] : [],
              search: this.search
            }
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const { hasNextPage, nodes } = fetchMoreResult.salePageTemplates
            return ({
              salePageTemplates: {
                __typename: previousResult.salePageTemplates.__typename,
                nodes: this.$_.uniqBy([
                  ...previousResult.salePageTemplates.nodes,
                  ...nodes
                ], 'id'),
                hasNextPage
              }
            })
          }
        })
        $state.loaded()
      } else {
        $state.complete()
      }
    }
  }
}
</script>
<style>
  .snt-template-category__menu {
    box-shadow: none;
  }
  .snt-template-category__menu .v-list {
    border-radius: 8px;
    background: #ffffff;
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  .snt-template-category__menu .v-list-item__title {
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
</style>
<style scoped>
  .snt-templates__headline {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #6B6B6B;
    margin-bottom: 16px;
  }
  .snt-templates-search {
    width: 320px;
  }
  .snt-template-img {
    background-color: #C9C9C9;
    padding: 8px;
  }
  .snt-templates__field {
    border-radius: 8px;
  }
  .snt-template-card__title {
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #787878;
    margin-right: 8px;
    white-space: nowrap;
  }
  .snt-template-card-name {
    width: 85%;
  }
  .snt-template-card__text {
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    color: #787878;
  }
  .snt-template-card {
    border-radius: 8px;
  }
  .no-data {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    padding: 7rem;
  }
</style>
