<template>
  <v-dialog
    v-model="dialog"
    width="320px"
    scrollable>
    <template v-slot:activator="{ attrs }">
      <v-list-item
        v-bind="attrs"
        link
        @click="setDialog(true, true)">
        <v-list-item-subtitle>
          <span class="error--text"> {{ $t('delete.btn') }}</span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-card color="snt-template-delete__card">
      <v-card-title class="pt-6 px-6 pb-0"></v-card-title>
      <v-card-text class="px-6">
        <div class="snt-template-delete__action">
          <v-btn
            x-small
            icon
            @click="setDialog(false)">
            <v-icon
              color="#6C6C6C"
              size="16">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="text-center mb-4">
          <v-icon
            color="error"
            size="53">
            $snt_trash_outline
          </v-icon>
        </div>
        <div class="snt-template-delete__title">
          {{ $t('delete.title') }}
        </div>
        <div class="snt-template-delete__description">
          {{ $t('delete.description') }}
        </div>
      </v-card-text>
      <v-card-actions class="pb-6 px-6">
        <v-spacer />
        <v-btn
          class="snt-template-delete__btn px-4"
          color="primary"
          text
          @click="setDialog(false)">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :loading="submitting"
          class="snt-template-delete__btn px-4"
          color="error"
          depressed
          @click="submit()">
          {{ $t('delete.btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DELETE_SALEPAGETEMPLATE } from '@/resources/graphql'

export default {
  props: {
    itemId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false
    }
  },
  methods: {
    async submit () {
      this.submitting = true
      await this.$apollo.mutate({
        mutation: DELETE_SALEPAGETEMPLATE,
        variables: {
          input: {
            id: this.itemId
          }
        }
      })
      window.location.reload()
    },
    setDialog (dialog = false, timeout = false) {
      if (timeout) {
        setTimeout(() => {
          this.dialog = dialog
        })
      } else {
        this.dialog = dialog
      }
    }
  }
}
</script>

<style scoped>
  .snt-template-action .v-list-item__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }
  .snt-template-delete__card {
    border-radius: 8px;
  }
  .snt-template-delete__btn {
    border-radius: 8px;
  }
  .snt-template-delete__action {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .snt-template-delete__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #6B6B6B;
  }
  .snt-template-delete__description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #B1B1B1;
  }
</style>
