<template>
  <v-dialog
    v-model="dialog"
    width="450px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs">
        <v-btn
          v-bind="attrs"
          color="primary"
          depressed
          v-on="on">
          {{ $t('createTemplate') }}
        </v-btn>
      </slot>
    </template>
    <v-card class="snt-template-form__card">
      <v-card-title class="pt-6 pb-0"></v-card-title>
      <v-card-text class="px-6">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <div class="snt-template-form__label mb-4">
            {{ $t('templateName') }}
          </div>
          <v-text-field
            v-model="name"
            :rules="rule.name"
            :placeholder="$t('templateName')"
            class="snt-template-form__field"
            autocomplete="off"
            background-color="#ffffff"
            autofocus
            dense
            outlined />
          <div class="snt-template-form__label mb-2">
            {{ $t('templateCategory') }}
          </div>
          <v-chip-group
            v-model="categories"
            :rules="rule.categories"
            active-class="primary"
            multiple
            column>
            <v-chip
              v-for="category in salePageTemplateCategoryEnum"
              :key="`chip-category-${category.name}`"
              :value="category.name"
              color="#EBEBEB">
              {{ $t(category.description) }}
            </v-chip>
          </v-chip-group>
          <v-input
            v-model="categories"
            :rules="rule.categories"
            class="px-3 mb-2"
          />
          <div class="snt-template-form__label mb-4">
            {{ $t('price') }}
          </div>
          <v-text-field
            v-model="price"
            :rules="rule.price"
            :placeholder="$t('price')"
            class="snt-template-form__field"
            type="number"
            background-color="#ffffff"
            suffix="THB"
            outlined
            dense />
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-6 px-6">
        <v-spacer />
        <v-btn
          class="snt-template-form__btn px-4"
          color="primary"
          outlined
          @click="setDialog(false)">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          :loading="submitting"
          class="snt-template-form__btn px-4"
          color="primary"
          depressed
          @click="submit()">
          {{ $t('completed') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueXTemplate } from '@shipnity/template/src/utils/dom'
import { CREATE_SALEPAGETEMPLATE, GET_SALEPAGETEMPLATECATEGORY_ENUM } from '@/resources/graphql'

export default {
  apollo: {
    salePageTemplateCategoryEnum: () => ({
      query: GET_SALEPAGETEMPLATECATEGORY_ENUM,
      update: (data) => data.__type.enumValues
    })
  },
  data () {
    return {
      dialog: false,
      valid: false,
      submitting: false,
      name: '',
      categories: [],
      price: '0',
      rule: {
        name: [
          (v) => !!v || this.$t('required'),
          (v) => (!!v && v.length > 2) || this.$t('minLength', [2]),
          (v) => (!!v && v.length <= 24) || this.$t('maxLength', [24])
        ],
        categories: [
          (v) => !!v || this.$t('required'),
          (v) => (!!v && !!v.length) || this.$t('required')
        ],
        price: [
          (v) => v !== '' || this.$t('required')
        ]
      }
    }
  },
  methods: {
    setTemplateCompute (template) {
      return new VueXTemplate({
        template,
        data: {
          theme: {
            color: {
              primary: '#1976D2',
              secondary: '#424242',
              accent: '#82B1FF'
            }
          }
        }
      }).compute()
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.submitting = true
        let input = {
          name: this.name,
          price: this.price.toString(),
          categories: this.categories,
          stylesheet: ''
        }
        const files = [
          {
            key: 'header',
            code: '<snt-app><snt-section name="Navbar"><snt-navbar /></snt-section></snt-app>'
          },
          {
            key: 'footer',
            code: '<snt-app><snt-section name="Footer"><snt-footer /></snt-section></snt-app>'
          },
          {
            key: 'landing',
            code: '<snt-app><snt-section name="Landing"><snt-text label="Hello world" /></snt-section></snt-app>'
            },
          {
            key: 'products',
            code: '<snt-app><snt-section name="Products"><snt-all-products /></snt-section></snt-app>'
          },
          {
            key: 'product',
            code: '<snt-app><snt-section name="Product"><snt-product-detail /></snt-section></snt-app>'
          },
          {
            key: 'order',
            code: '<snt-app><snt-section name="Order"><snt-order-detail /></snt-section></snt-app>'
          },
          {
            key: 'orders',
            code: '<snt-app><snt-section name="Orders"><snt-orders /></snt-section></snt-app>'
          },
          {
            key: 'cart',
            code: '<snt-app><snt-section name="Cart"><snt-cart /></snt-section></snt-app>'
          },
          {
            key: 'checkout',
            code: '<snt-app> <snt-section name="Checkout"><snt-checkout /></snt-section></snt-app>'
          },
          {
            key: 'payment',
            code: '<snt-app> <snt-section name="Payment"><snt-payment /></snt-section></snt-app>'
          }
        ]
        files.forEach((file) => {
          const { keys, values } = this.setTemplateCompute(file.code)
          input = this.$_.assignIn(input, {
            [file.key]: file.code,
            [`${file.key}Keys`]: keys,
            [`${file.key}Values`]: values
          })
        })
        const { data: { createSalePageTemplate: { template } } } = await this.$apollo.mutate({
          mutation: CREATE_SALEPAGETEMPLATE,
          variables: {
            input
          }
        })
        this.submitting = false
        this.$router.push({ path: `/templates/${template.id}` })
      }
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>

<style scoped>
  .snt-template-form__card {
    border-radius: 8px;
  }
  .snt-template-form__label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #6B6B6B;
  }
  .snt-template-form__field {
    border-radius: 8px;
  }
  .snt-template-form__btn {
    border-radius: 8px;
  }
</style>
