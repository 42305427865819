<template>
  <div>
    <v-btn
      :for="`cover-upload-${itemId}`"
      :loading="submitting"
      class="snt-template-upload"
      color="#6C6C6C"
      tag="label"
      icon>
      <v-icon>$snt_camera</v-icon>
    </v-btn>
    <file-upload
      ref="coverUplaod"
      v-model="files"
      :name="`cover-upload-${itemId}`"
      :drop="!edit"
      extensions="gif,jpg,jpeg,png,webp"
      accept="image/png,image/gif,image/jpeg,image/webp"
      @input-filter="inputFileFilter"
      @input-file="inputFile" />
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import { UPDATE_SALEPAGETEMPLATE } from '@/resources/graphql'

export default {
  components: {
    FileUpload
  },
  props: {
    itemId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      submitting: false,
      files: [],
      edit: false,
      error: null
    }
  },
  methods: {
    async submit (file) {
      this.submitting = true
      await this.$apollo.mutate({
        mutation: UPDATE_SALEPAGETEMPLATE,
        variables: {
          input: {
            id: this.itemId,
            snapshot: file
          }
        }
      })
      this.submitting = false
    },
    inputFile (newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick(() => {
          const fileObj = this.files[0]
          this.submit(fileObj.file)
        })
      }
    },
    inputFileFilter (newFile, oldFile, prevent) {
      this.error = null
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.error = '* Your choice is not a picture'
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // eslint-disable-next-line no-param-reassign
        newFile.url = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          // eslint-disable-next-line no-param-reassign
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
      return null
    }
  }
}
</script>

<style scoped>
  .snt-template-upload {
    background-color: #f4f6fa;
    cursor: pointer;
  }
</style>
