<template>
  <v-menu
    :close-on-content-click="true"
    content-class="snt-template-action__menu"
    min-width="135"
    nudge-right="20px"
    transition="slide-y-transition"
    offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div class="d-inline-block">
        <v-chip
          v-if="itemLatestStatus"
          :color="statusColor"
          class="ma-2 px-4"
          small
          label>
          {{ statusI18n }}
        </v-chip>
        <v-btn
          v-bind="attrs"
          class="snt-template-action__btn"
          color="primary"
          small
          depressed
          outlined
          v-on="on">
          <v-icon size="30">
            mdi-menu-down
          </v-icon>
        </v-btn>
      </div>
    </template>
    <v-list
      class="snt-template-action"
      outlined
      dense>
      <publish-button
        :item-id="itemId"
        :disabled="inReview" />
      <v-list-item
        :to="`/editor/${itemId}`"
        target="_blank"
        link>
        <v-list-item-subtitle>
          <span> {{ $t('preview') }}</span>
        </v-list-item-subtitle>
      </v-list-item>
      <template-setting
        :item-id="itemId"
        :item-name="itemName"
        :item-categories="itemCategories"
        :item-price="itemPrice" />

      <v-list-item
        :disabled="submitting"
        link
        @click="copy()">
        <v-list-item-subtitle>
          <span> {{ $t('copy') }}</span>
        </v-list-item-subtitle>
      </v-list-item>
      <unpublish-button
        v-if="isPublished"
        :item-id="itemId" />
      <delete-button :item-id="itemId" />
    </v-list>
  </v-menu>
</template>

<script>
import { CREATE_SALEPAGETEMPLATE, GET_SALEPAGETEMPLATESTATUS_ENUM } from '@/resources/graphql'
import _ from 'lodash'
import UnpublishButton from './UnpublishButton.vue'
import PublishButton from './PublishButton.vue'
import DeleteButton from './DeleteButton.vue'
import TemplateSetting from './TemplateSetting.vue'

export default {
  components: { UnpublishButton, PublishButton, DeleteButton, TemplateSetting },
  apollo: {
    salePageTemplateStatusEnum: () => ({
      query: GET_SALEPAGETEMPLATESTATUS_ENUM,
      update (data) {
        return data.__type.enumValues
      }
    })
  },
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    itemName: {
      type: [String],
      required: true
    },
    itemCategories: {
      type: [Array],
      required: true
    },
    itemPrice: {
      type: [Number, String],
      required: true
    },
    itemHeader: {
      type: [String],
      default: ''
    },
    itemFooter: {
      type: [String],
      default: ''
    },
    itemLanding: {
      type: [String],
      default: ''
    },
    itemProducts: {
      type: [String],
      default: ''
    },
    itemProduct: {
      type: [String],
      default: ''
    },
    itemCheckout: {
      type: [String],
      default: ''
    },
    itemOrder: {
      type: [String],
      default: ''
    },
    itemOrders: {
      type: [String],
      default: ''
    },
    itemCart: {
      type: [String],
      default: ''
    },
    itemStylesheet: {
      type: [String],
      default: ''
    },
    itemLatestStatus: {
      type: [String],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false,
      salePageTemplateStatusEnum: []
    }
  },
  computed: {
    status () {
      return this.$_.find(this.salePageTemplateStatusEnum, (v) => v.name === this.itemLatestStatus) || {}
    },
    statusI18n () {
      return this.status.description ? this.$t(`templateStatus.${this.status.description}`) : '...'
    },
    statusColor () {
      switch (this.status.name) {
        case 'IN_REVIEW':
          return 'primary'
        case 'PUBLISHED':
          return 'success'
        case 'REJECTED':
          return 'error'
        case 'DRAFT':
          return 'warning'
        default:
          return 'default'
      }
    },
    inReview () {
      return this.itemLatestStatus === 'IN_REVIEW'
    },
    isPublished () {
      return this.itemLatestStatus === 'PUBLISHED'
    }
  },
  methods: {
    async copy () {
      this.submitting = true
      const { data: { createSalePageTemplate: { template } } } = await this.$apollo.mutate({
        mutation: CREATE_SALEPAGETEMPLATE,
        variables: {
          input: {
            name: this.itemName,
            price: _.toString(this.itemPrice),
            categories: this.itemCategories,
            header: this.itemHeader,
            footer: this.itemFooter,
            landing: this.itemLanding,
            products: this.itemProducts,
            product: this.itemProduct,
            checkout: this.itemCheckout,
            order: this.itemOrder,
            orders: this.itemOrders,
            cart: this.itemCart,
            stylesheet: this.itemStylesheet
          }
        }
      })
      this.submitting = false
      this.$router.push({ path: `/templates/${template.id}` })
    }
  }
}
</script>
<style>
  .snt-template-action .v-list-item--disabled .v-list-item__subtitle {
    color: #B1B1B1;
  }
</style>
<style scoped>
  .snt-template-action.v-list  {
    border-radius: 8px;
    background: #ffffff;
  }
  .snt-template-action__menu {
    box-shadow: none;
  }
  .snt-template-action__btn.v-btn {
    background: #ffffff;
    min-width: 32px;
    padding: 0;
  }
  .snt-template-action .v-list-item__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }
</style>
