<template>
  <v-dialog
    v-model="dialog"
    width="320px"
    scrollable>
    <template v-slot:activator="{ attrs }">
      <v-list-item
        v-bind="attrs"
        :disabled="disabled"
        link
        @click="setDialog(true, true)">
        <v-list-item-subtitle>
          <span class="primary--text"> {{ $t('publish.btn') }}</span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-card class="snt-template-publish__card">
      <v-card-title class="pt-6 px-6 pb-0"></v-card-title>
      <v-card-text class="px-6">
        <div class="snt-template-publish__action">
          <v-btn
            x-small
            icon
            @click="setDialog(false)">
            <v-icon
              color="#6C6C6C"
              size="16">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="text-center mb-4">
          <v-icon size="53">
            $snt_puiblish
          </v-icon>
        </div>
        <div class="snt-template-publish__title">
          {{ $t('publish.title') }}
        </div>
        <div class="snt-template-publish__description">
          {{ $t('publish.description') }}
        </div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-radio-group
            v-model="versionUpdateType"
            :rules="rule.versionUpdateType"
            hide-details
            dense
            column>
            <v-radio
              v-for="item in versionUpdateTypeEnum"
              :key="`version-type-${item.name}`"
              :label="$t(item.description)"
              :value="item.name" />
          </v-radio-group>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-6 px-6">
        <v-spacer />
        <v-btn
          class="snt-template-publish__btn px-4"
          text
          @click="setDialog(false)">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :loading="submitting"
          class="snt-template-publish__btn px-4 ml-3"
          color="primary"
          depressed
          @click="submit()">
          {{ $t('publish.btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import { GET_SALEPAGETEMPLATEVERSIONUPDATETYPE_ENUM, REVIEW_SALEPAGETEMPLATE } from '@/resources/graphql'

export default {
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    disabled: {
      type: [Boolean],
      default: false
    }
  },
  apollo: {
    versionUpdateTypeEnum: () => ({
      query: GET_SALEPAGETEMPLATEVERSIONUPDATETYPE_ENUM,
      update: (data) => data.__type.enumValues,
      result ({ data: { __type } }) {
        const head = _.head(__type.enumValues)
        if (head) {
          this.versionUpdateType = head.name
        }
      }
    })
  },
  data () {
    return {
      dialog: false,
      submitting: false,
      valid: false,
      rule: {
        versionUpdateType: [
          (v) => !!v || this.$t('required')
        ]
      },
      versionUpdateTypeEnum: [],
      versionUpdateType: null
    }
  },
  methods: {
    async submit () {
      if (this.$refs.form.validate()) {
        this.submitting = true
        await this.$apollo.mutate({
          mutation: REVIEW_SALEPAGETEMPLATE,
          variables: {
            input: {
              id: this.itemId,
              updateType: this.versionUpdateType
            }
          }
        })
        this.submitting = false
        this.setDialog(false)
      }
    },
    setDialog (dialog = false, timeout = false) {
      if (timeout) {
        setTimeout(() => {
          this.dialog = dialog
        })
      } else {
        this.dialog = dialog
      }
    }
  }
}
</script>

<style scoped>
  .snt-template-action .v-list-item__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }
  .snt-template-publish__card {
    border-radius: 8px;
  }
  .snt-template-publish__action {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .snt-template-publish__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #6B6B6B;
  }
  .snt-template-publish__description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #B1B1B1;
  }
  .snt-template-publish__btn {
    border-radius: 8px;
  }
</style>
